<template>
	<div class="balance">
		<dashboard-item-header :title="$t('dashboard.balance')" image-source="account-icon.svg">
			<template #contextMenu>
				<context-menu-item
					:title="$t('dashboard.account-statement')"
					icon="account-statement-small.svg"
					icon-alt="Account statement"
					:on-click="navigateToAccountStatement"
				/>
			</template>
		</dashboard-item-header>
		<Spacer height size="xl" />
		<div class="content">
			<div>
				<DoughnutChart :data="chartData" :total-balance="totalBalance" />

				<Spacer height size="l" />
				<CButton v-if="wallets.data !== null && wallets.data.length >= 2" dark @click="navigateTo('/transfer')">
					{{ $t('dashboard.transfer-between-wallets') }}
				</CButton>
				<Spacer height size="l" />
			</div>
			<div class="wallets">
				<span class="wallet-info">{{ $t('dashboard.select-wallet-label') }}</span>
				<div v-if="wallets.error && !wallets.loading" class="non-wallets-info">
					<span class="wallets-error">{{ $t(wallets.error) }}</span>
				</div>
				<div v-if="wallets.loading" class="non-wallets-info">
					<img class="rotate" :src="require('../../assets/icons/pending-2.svg')" />
				</div>
				<div v-if="!wallets.loading && !wallets.error" class="wallets-list">
					<WalletRow
						v-for="(wallet, index) in wallets.data"
						:id="wallet.id"
						:key="wallet.id"
						:username="wallet.username"
						:currency="wallet.currency"
						:balance="(Number(wallet.balance) + Number(wallet.credit)).toString()"
						:border="index !== wallets.data.length - 1"
						:selected="wallet.id === selectedWalletId"
						:on-row-clicked="onWalletClicked"
						class="wallet"
					/>
				</div>
			</div>
		</div>
		<Spacer height size="xl" />
	</div>
</template>

<script>
import Spacer from '@/shared/spacer/Spacer.vue';
import DashboardItemHeader from '@/shared/dashboard-item-header/DashboardItemHeader';
import DoughnutChart from '@/shared/doughnut-chart/DoughnutChart.vue';
import CButton from '@/shared/cbutton/CButton';
import WalletRow from '@/shared/dashboard-balance/WalletRow';
import apiClient from '../../api';
import ContextMenuItem from '@/shared/context-menu-item/ContextMenuItem';

export default {
	name: 'DashboardBalance',
	components: {
		DashboardItemHeader,
		DoughnutChart,
		WalletRow,
		CButton,
		Spacer,
		ContextMenuItem,
	},
	data() {
		return {
			chartData: [],
			wallets: {
				loading: false,
				data: null,
				error: 0,
			},
			selectedWalletId: null,
			totalWalletsBalance: null,
			totalBalance: 0,
		};
	},
	async mounted() {
		await this.getUsersWallets();
	},
	methods: {
		navigateToAccountStatement() {
			this.navigateTo('/account-statement');
		},
		async getUsersWallets() {
			try {
				this.wallets.loading = true;
				const { wallets } = await apiClient.fetchWalletsWithBalances();

				this.wallets.data = wallets
					.filter((w) => !w.isDemo)
					.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

				const primaryWallet = wallets.find((w) => w.primary);
				this.selectedWalletId = primaryWallet.id;
				this.totalBalance = wallets.reduce((p, c) => p + Number(c.balance) + Number(c.credit), 0);
				this.createChart(primaryWallet);
			} catch (e) {
				this.wallets.error = 'dashboard.failed-load-wallets';
			} finally {
				this.wallets.loading = false;
			}
		},
		onWalletClicked(username) {
			const wallet = this.wallets.data.find((w) => w.username == username);
			this.selectedWalletId = wallet.id;
			this.createChart(wallet);
		},
		createChart(wallet) {
			const totalWalletBalance = Number(wallet.balance) + Number(wallet.credit);
			this.chartData = [
				{ label: wallet.username, value: totalWalletBalance, color: '#33FF77' },
				{ label: 'Rest', value: Number(this.totalBalance - totalWalletBalance), color: 'gray' },
			];
		},
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.balance {
	@include card;
}

.content {
	display: grid;
	gap: $spacer-m;
	grid-template-columns: 1fr 1fr;

	@include max-screen($md) {
		grid-template-columns: 1fr;
	}
}

.non-wallets-info {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
}

.wallets {
	display: flex;
	flex-direction: column;
	gap: 10px;

	&-info {
		align-self: start;
	}

	&-error {
		color: $error-pink;
	}

	&-list {
		@include min-screen($md) {
			overflow: auto;
			max-height: 250px;
		}

		@include max-screen($md) {
			overflow: visible;
		}
	}
}

.rotate {
	animation: rotation 2s infinite linear;
	width: 50px;
	height: 50px;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
</style>
