<template>
	<div class="doughnut-chart">
		<canvas ref="chartCanvas" class="canvas"></canvas>
		<div class="total">
			<span class="title">{{ $t('dashboard.total-balance') }}</span>
			<span class="value">{{
				Intl.NumberFormat($i18n.locale, { style: 'currency', currency: 'USD' }).format(totalBalance)
			}}</span>
		</div>
	</div>
</template>

<script>
import Chart from 'chart.js';

export default {
	name: 'DoughnutChart',
	props: {
		data: {
			type: Array,
			required: true,
		},
		totalBalance: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			chart: null,
		};
	},
	watch: {
		data() {
			this.renderChart();
		},
	},
	mounted() {
		this.renderChart();
		window.addEventListener(
			'resize',
			function () {
				if (this.timer) {
					clearTimeout(this.timer);
				}
				this.timer = setTimeout(this.renderChart, 500);
			}.bind(this)
		);
	},
	methods: {
		renderChart() {
			if (this.chart) {
				this.chart.destroy();
			}

			const chartData = {
				labels: this.data.map((element) => element.label),
				datasets: [
					{
						data: this.data.map((element) => element.value),
						backgroundColor: this.data.map((element) => element.color),
						borderWidth: 3,
						borderColor: '#1d2024',
					},
				],
			};
			const options = {
				cutoutPercentage: 93,
				legend: {
					display: false,
				},
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							const index = tooltipItem.index;
							const label = data.labels[index];
							const value = data.datasets[0].data[index];
							const valueWCurrency = Intl.NumberFormat(this.$i18n.locale, {
								style: 'currency',
								currency: 'USD',
							}).format(Number(value));

							return `${label}: ${valueWCurrency}`;
						},
					},
				},
			};

			this.chart = new Chart(this.$refs.chartCanvas, {
				type: 'doughnut',
				data: chartData,
				options,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';

.doughnut-chart {
	display: flex;
	justify-content: center;
	align-items: center;
}

.canvas {
	width: 100%;
	max-width: 100%;
}

.total {
	position: absolute;
	display: flex;
	flex-direction: column;
}

.title {
	font-size: $title-md;
}

.value {
	font-size: $title-lg - 10px;
}

.rotate {
	animation: rotation 2s infinite linear;
	width: 60px;
	height: 60px;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
</style>
