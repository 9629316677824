<template>
	<div class="container" :class="{ 'border-bottom': border && !selected, highlighted: selected }" @click="onRowClick">
		<img v-if="selected" class="wallet-left" :src="require('../../assets/dashboard-icons/wallet-left.svg')" />
		<div class="wallet">
			<div class="wallet-inner">
				<div>
					<span class="name">{{ username }}</span>
					<span class="label">{{ $t('dashboard.current-balance') }}</span>
				</div>
			</div>
			<div class="wallet-inner">
				<span v-if="balance">
					{{ Intl.NumberFormat($i18n.locale, { style: 'currency', currency: currency }).format(balance) }}</span
				>
			</div>
		</div>
		<div v-if="selected" class="buttons">
			<CButton primary full-width @click="navigateTo(`/payments/deposit/${username}`)">
				{{ $t('actions.deposit') }}
			</CButton>
			<CButton dangerPink full-width @click="navigateTo(`/payments/withdrawal/${username}`)">
				{{ $t('actions.withdraw') }}
			</CButton>
		</div>

		<Spacer height size="l" />
	</div>
</template>

<script>
import Spacer from '@/shared/spacer/Spacer.vue';
import CButton from '@/shared/cbutton/CButton';

export default {
	name: 'WalletRow',
	components: {
		Spacer,
		CButton,
	},
	props: {
		username: {
			type: String,
			default: null,
		},
		currency: {
			type: String,
			default: null,
		},
		balance: {
			type: String,
			default: null,
		},
		border: {
			type: Boolean,
			default: true,
		},
		selected: {
			type: Boolean,
			default: false,
		},
		onRowClicked: {
			type: Function,
			default: null,
		},
	},
	methods: {
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
		onRowClick() {
			this.onRowClicked(this.username);
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';

.container {
	display: flex;
	flex-direction: column;
	position: relative;
	padding: $spacer-xl;
	cursor: pointer;
}

.highlighted {
	@include card-highlighted;
}

.wallet {
	display: flex;
	justify-content: space-between;
	padding-bottom: $spacer-xs;
}

.wallet-left {
	position: absolute;
	width: 5px;
	height: 58px;
	left: 0px;
	top: 10px;
}

.border-bottom {
	border-bottom: 1px solid #2a2e31;
}

.wallet-inner {
	display: flex;
	align-items: center;
	gap: $spacer-m;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}

.wallet-info {
	display: flex;
}

.error {
	color: $error-pink;
}

.name {
	font-size: 14px;
	color: #ffffff;
}

.label {
	font-size: 11px;
	color: #ffffff;
	opacity: 0.5;
	text-align: left;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.rotate {
	animation: rotation 2s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
</style>
