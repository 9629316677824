<template>
	<DashboardCard>
		<template #header>
			<dashboard-item-header
				:title="$t('dashboard.partner-program.title')"
				image-source="partner-program.svg"
				:on-context-menu-open="setCopiedToFalse"
			>
				<template #contextMenu>
					<context-menu-item
						:title="copied ? $t('actions.copied') : $t('dashboard.partner-program.copy-to-clipboard')"
						icon="referral-link-small.svg"
						icon-alt="Copy referral link"
						:on-click="copyReferralLinkClipboard"
					/>
				</template>
			</dashboard-item-header>
		</template>
		<template #content>
			<div class="card-column">
				<span class="label">{{ $t('dashboard.partner-program.earnings-last-month') }}</span>
				<div class="column-icon">
					<img class="content-image" :src="require('../../assets/icons/graph.svg')" />
					<Spacer width size="s" />
					<div v-if="affiliateEarnings.error !== null" class="error">
						<span>{{ $t(affiliateEarnings.error) }}</span>
					</div>
					<div v-if="affiliateEarnings.loading">
						<img class="rotate" :src="require('../../assets/icons/pending-2.svg')" />
					</div>
					<span v-if="!affiliateEarnings.loading && !affiliateEarnings.error" class="value">{{
						Intl.NumberFormat($i18n.locale, { style: 'currency', currency: 'USD' }).format(affiliateEarnings.earnigns)
					}}</span>
				</div>
			</div>
		</template>
		<template #footer>
			<CButton dark full-width @click="navigateTo(affiliatePortalUrl)">
				{{ $t('dashboard.partner-program.affiliate-portal') }}
			</CButton>
		</template>
	</DashboardCard>
</template>

<script>
import apiClient from '../../api';
import { mapGetters } from 'vuex';
import DashboardCard from '@/components/dashboard/DashboardCard';
import DashboardItemHeader from '@/shared/dashboard-item-header/DashboardItemHeader';
import CButton from '@/shared/cbutton/CButton';
import Spacer from '@/shared/spacer/Spacer.vue';
import ContextMenuItem from '@/shared/context-menu-item/ContextMenuItem';

export default {
	name: 'PartnerProgram',
	components: {
		DashboardCard,
		DashboardItemHeader,
		ContextMenuItem,
		CButton,
		Spacer,
	},
	data() {
		return {
			affiliatePortalUrl: `${process.env.VUE_APP_AFFILIATE_URL}login?request_login=true`,
			affiliateEarnings: {
				earnigns: null,
				loading: false,
				error: null,
			},
			copied: false,
		};
	},
	computed: {
		...mapGetters({
			user: 'getUser',
			username: 'getUsername',
		}),
	},
	async mounted() {
		await this.fetchAffiliateEarnings();
	},
	methods: {
		setCopiedToFalse() {
			this.copied = false;
		},
		copyReferralLinkClipboard() {
			const url = `${process.env.VUE_APP_URL}/signup?wtreferrer=${this.user.refKey}`;
			navigator.clipboard.writeText(url);
			this.copied = true;

			setTimeout(() => {
				this.copied = false;
			}, 3000);
		},
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
		async fetchAffiliateEarnings() {
			try {
				this.affiliateEarnings.loading = true;
				const data = await apiClient.getAffiliateEarnings();
				this.affiliateEarnings.earnings = data.earnigns;
			} catch (e) {
				this.affiliateEarnings.error = 'dashboard.partner-program.failed-to-load-earnings';
			} finally {
				this.affiliateEarnings.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.card {
	&-column {
		display: flex;
		flex-direction: column;
		text-align: left;

		.column-icon {
			display: flex;
			align-items: center;
		}
	}

	&-row {
		display: flex;
	}
}

.content-image {
	width: 14px;
	height: 14px;
}

.value {
	font-size: $title-md;
	color: $white;
	font-weight: $bold;
}

.label {
	font-size: $label-md;
	color: $white;
	opacity: 0.5;
}

.label-image {
	width: 30px;
	height: 30px;
}
</style>
