<template>
	<DashboardCard>
		<template #header>
			<dashboard-item-header :title="$t('dashboard.education')" image-source="personal-details.svg" />
		</template>
		<template #content>
			<p class="label">{{ $t('dashboard.trading.paragraph') }}</p>
		</template>
		<template #footer>
			<CButton dark full-width @click="navigateTo('/education')">
				{{ $t('dashboard.education') }}
			</CButton>
		</template>
	</DashboardCard>
</template>

<script>
import DashboardCard from '@/components/dashboard/DashboardCard';
import DashboardItemHeader from '@/shared/dashboard-item-header/DashboardItemHeader';
import CButton from '@/shared/cbutton/CButton';

export default {
	name: 'EducationCard',
	components: {
		DashboardCard,
		DashboardItemHeader,
		CButton,
	},
	methods: {
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.label {
	font-size: $label-md;
	color: $white;
	opacity: 0.5;
}
</style>
